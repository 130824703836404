import TrailBlazerSeal from '../../assets/images/carousel/TrailBlazer.svg';
import CompassSeal from '../../assets/images/carousel/Compass.svg';
import ExplorerSeal from '../../assets/images/carousel/Explorer.svg';
import SummitSeal from '../../assets/images/carousel/Summit.svg';
import DeltaSeal from '../../assets/images/carousel/Delta.svg';
import CrucibleSeal from '../../assets/images/carousel/Crucible.png';
import DracoSeal from '../../assets/images/carousel/Draco.png';
import { PRIMARY_ROUTES } from '../Routes/PrimaryRoutes';

export interface InitiativeLink {
  text: string;
  url: string;
}

export interface Initiative {
  image: string;
  title: string;
  description: string;
  name: string;
  links: InitiativeLink[];
}

export const Initiatives: Initiative[] = [
  {
    image: DeltaSeal,
    name: 'Delta',
    title: 'Enable Space Operations',
    description:
      'As the Space Force continues to grow, so will its mission needs. Space CAMP will turn these mission needs into interoperable digital capabilities by partnering with current programs of record and other organizations. When moving forward with a digital mindset, the Space Force will be outfitted with the capabilities to stay ahead of our adversaries and ensure our success in space.',
    links: [
      { text: 'Battle Drill - Event Response Management', url: `${PRIMARY_ROUTES.work}/Battle Drill` },
      { text: 'Dragon Army - Space Operations Testing Range', url: `${PRIMARY_ROUTES.work}/Dragon Army Ops` }
    ]
  },
  {
    image: TrailBlazerSeal,
    title: 'Urgent Needs Applications',
    name: 'Trailblazer',
    description:
      'The US Space Force operates in an unpredictable dynamic environment. This ever-changing battleground requires solutions that can adapt to new requirements quickly and effectively. Space CAMP is postured to rapidly develop and deploy applications that solve acute mission needs. We fill the gaps between program offices, partnering directly with organizations to deliver iterative software solutions directly to the end-user.',
    links: [
      { text: 'Perimeter 9 - Pandemic Case Management Suite', url: `${PRIMARY_ROUTES.work}/Perimeter 9` },
      { text: 'IRIS - Launch Range Asset Management', url: `${PRIMARY_ROUTES.work}/IRIS` }
    ]
  },
  {
    image: SummitSeal,
    title: 'Agile Training',
    name: 'Summit',
    description:
      'Space CAMP is developing the next generation of innovators, guiding them toward the adoption of industry proven Agile, Lean, and DevSecOps practices. Through our lessons learned and our relationships we provide agile coaching, mentorship, and education to our mission partners. Our community understands that it is important to learn fast, grow, and iterate. The Summit badge represents our initiative to provide superior coaching to those in the organization and our partners.',
    links: [{ text: 'Supra Coders - Software Development Immersive', url: `https://supracoders.us/` }]
  },
  {
    image: CompassSeal,
    title: 'Digital Transformation',
    name: 'Compass',
    description:
      'Modernizing legacy software is essential to ensuring digital dominance. Legacy systems are often monolithic, costly to sustain, and difficult to update. We will partner with your team to transform a legacy application into a modern cloudnative solution. Our services include transitioning software into cloud-based servers, containerization, interoperability, redesign into a microservice architecture, CtF certification, and accreditation of CI/CD pipelines.',
    links: [{ text: 'Decon - Laser Orbital Safety', url: `${PRIMARY_ROUTES.work}/Decon` }]
  },
  {
    image: ExplorerSeal,
    title: 'Research & Development',
    name: 'Explorer',
    description:
      ' We are eliminating the “Valley of Death” by bridging the gap between R&D initiatives and operational capabilities. Through our partnerships with Small Business Innovation Research (SBIR) companies, AFRL, and other R&D think-tanks, we provide the mentorship, coaching, infrastructure, and culture needed to enable out-of-the-box thinking.  Our goal is to accelerate deployment of right solutions to the operator.',
    links: [
      { text: 'Space Cockpit - Space Domain Awareness', url: `${PRIMARY_ROUTES.work}/Space Cockpit` },
      { text: 'SCINET - Security Application Management Suite', url: `${PRIMARY_ROUTES.work}/SCINET` }
    ]
  },
  {
    image: CrucibleSeal,
    title: 'Agile Application Testing',
    name: 'Crucible',
    description:
      ' To deliver capability at the speed of relevance, we seek to streamline the entire software delivery pipeline from concept to operations. Operational military systems have additional mission-assurance demands that must also be integrated into the development process (beyond DevSecOps). We have embedded members of the operational test community in our teams and are investing in automation that will allow DT/OT to occur on a cadence matching software development cycles, such that every feature can be operationally tested prior to deployment.',
    links: [{ text: 'Dragon Army - Space Operations Testing Range', url: `${PRIMARY_ROUTES.work}/Dragon Army Ops` }]
  },
  {
    image: DracoSeal,
    title: 'Mission-Focused Experimentation',
    name: 'Draco',
    description:
      ' Commercial Sprint Advanced Concept Training (SACT) and Dragon Army Ops Days provide a mission-focused competitive environment where technologies are proven to warfighters, partnerships organically grow, and clarity is gained through action. This is about working components over Power Point Prophecy, failing fast (and learning) over painstaking requirements processes, and dialing into solutions better and faster through continuous real-time experimentation and live use of new technologies.',
    links: [{ text: 'Dragon Army - Space Operations Testing Range', url: `${PRIMARY_ROUTES.work}/Dragon Army Ops` }]
  }
];
