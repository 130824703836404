import React, { FunctionComponent, ReactElement } from 'react';
import InternalOrExternalLink from '../../../InternalOrExternalLink/InternalOrExternalLink';
import './InnovationCard.css';

export interface InnovationCardProps {
  icon: string;
  title: string;
  textContent: string;
  url: string;
  buttonText: string;
  classString: string;
}

const InnovationCard: FunctionComponent<InnovationCardProps> = (props): ReactElement => {
  return (
    <div className="innovation-card-main p-8 bg-dark-deepSea items-start xl:w-7/25 lg:w-5/12 md:w-1/2 sm:w-3/5 xs:w-4/5 mb-8 xl:mb-0">
      <img src={props.icon} alt="" />
      <p className="w-2/3 xl:text-2xl lg:text-2xl md:text-xl sm:text-xl xs:text-xl mt-10 mb-8 font-oswald text-white text-opacity-87">
        {props.title}
      </p>
      <p className="font-light text-white text-opacity-74 mb-10">{props.textContent}</p>
      {props.children}
      <InternalOrExternalLink url={props.url} text={props.buttonText} classString={props.classString} />
    </div>
  );
};

export default InnovationCard;
