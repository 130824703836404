import _, { Dictionary } from 'lodash';
import moment from 'moment';
import { z } from 'zod';
import { TagsEnum } from '../enums/Tag.enum';

const DATE_REGEX = /^\d{1,2}\/\d{1,2}\/\d{4}$/g; // 4/23/2022
const TIME_REGEX = /^Date\(\d{4},\d{1,2},\d{1,2},(\d{1,2}),(\d{1,2}),\d{1,2}\)$/g; // Date(1899,11,30,14,30,0) We only care about the 14 and 30

/**
 *
 * @param date Expecting 4/23/2023 format
 * @param time Expecting Date(1899, 11, 30, 14, 30, 0) format, we only care about the 14 and 30 which represents hour and minute
 * @returns 2023-08-25T14:30:00 OR 2023-08-25T00:00:00 if time is not provided
 */
const formatToISO = (date: string, time?: string) => {
  const splitDate = date
    .split('/')
    .map((numStr: string) => parseInt(numStr).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }));
  const isoDate = [splitDate[2], splitDate[0], splitDate[1]].join('-');

  let isoTime = 'T00:00:00';
  if (time) {
    const [, hourStr, minutesStr] = Array.from(time.matchAll(TIME_REGEX))[0];
    const hour = parseInt(hourStr).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
    const minutes = parseInt(minutesStr).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
    isoTime = `T${hour}:${minutes}:00`;
  }

  const isoDatetime = isoDate + isoTime;

  return isoDatetime;
};

export const ZodColumnatedEvent = z.preprocess(
  dirtyEvent => _.mapKeys(dirtyEvent as Dictionary<string>, (v, k) => _.camelCase(k)),
  z.object({
    eventName: z.string({ required_error: 'Event Name:\trequired' }),
    startDate: z.string({ required_error: 'Start Date:\trequired' }).regex(DATE_REGEX),
    startTime: z
      .string()
      .regex(_.cloneDeep(TIME_REGEX), { message: 'Start Time: \tDoes not match regex requirements' })
      .optional(),
    endDate: z.string({ required_error: 'End Date:\trequired' }).regex(DATE_REGEX),
    endTime: z
      .string()
      .regex(_.cloneDeep(TIME_REGEX), { message: 'End Time:\tDoes not match regex requirements' })
      .optional(),
    tag1: TagsEnum.optional(),
    tag2: TagsEnum.optional(),
    description: z.string({ required_error: 'Description:\trequired' }),
    link: z.string().optional()
  })
);

export const ZodEvent = z
  .object({
    eventName: z.string(),
    start: z.string().transform(datetime => moment(datetime)),
    end: z.string().transform(datetime => moment(datetime)),
    tags: z.array(TagsEnum).max(2),
    description: z.string(),
    link: z.string().optional()
  })
  .refine(event => event.start.isBefore(event.end), {
    path: ['Start & End Datetime'],
    message: 'Start & End Datetime:\tStart datetime cannot be after the end datetime'
  });
export type ZodEvent = z.infer<typeof ZodEvent>;

export const eventTransformer = ZodColumnatedEvent.transform(event => {
  const eventName = event.eventName;
  const start = formatToISO(event.startDate, event.startTime);
  const end = formatToISO(event.endDate, event.endTime);
  const tags = [event.tag1, event.tag2].filter(tag => tag);
  const description = event.description;
  const link = event.link;

  return { eventName, start, end, tags, description, link };
}).pipe(ZodEvent);
export type ValidEvent = z.infer<typeof eventTransformer>;
