import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { PRIMARY_ROUTES } from './common/Routes/PrimaryRoutes';
import HomePage from './components/HomePage/HomePage';
import Footer from './components/Footer/Footer';
import ContactUs from './components/ContactUs/ContactUs';
import NavBar from './components/NavBar/NavBar';
import WorkPage from './components/WorkPage/WorkPage';
import ProcessPage from './components/ProcessPage/ProcessPage';
import NavBarCollapsed from './components/NavBar/NavBarCollapsed/NavBarCollapsed';
import ReactGA from 'react-ga';
import envVariables from './common/Data/envVariables';
import InitiativesPage from './components/InitiativesPage/InitiativesPage';
import RedirectWrapper from './components/RedirectWrapper/RedirectWrapper';
import PartnerBanner from './components/OurPartners/PartnerBanner';
import AscensionDay from './components/AscensionDay/AscensionDay';
import SpaceCampSocial from './components/SpaceCampSocial/SpaceCampSocial';
import CareersPage from './components/CareersPage/CareersPage';
import EventsPage from './components/EventsPage/EventsPage';
import TeamPage from './components/TeamPage/TeamPage';
import './App.css';

const App: FunctionComponent = (): ReactElement => {
  const [loaded, setLoaded] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    if (!loaded) {
      ReactGA.initialize(envVariables.trackingId, { testMode: envVariables.develop, debug: envVariables.develop });
      setLoaded(true);
    }
  }, [loaded]);

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.origin + pathname);
  }, [pathname]);

  return (
    <div className="app">
      <div className="xl:visible invisible fixed w-full z-50">
        <NavBar />
      </div>
      <div className="xl:invisible fixed w-full z-50">
        <NavBarCollapsed />
      </div>
      <div className="routeContainer xl:pt-20">
        <Switch>
          <Route path={PRIMARY_ROUTES.process}>
            <ProcessPage />
          </Route>
          <Route path={PRIMARY_ROUTES.work.concat('/:name')}>
            <WorkPage />
          </Route>
          <Route path={PRIMARY_ROUTES.work}>
            <WorkPage />
          </Route>
          <Route path={PRIMARY_ROUTES.contact.concat('/:section')}>
            <ContactUs />
          </Route>
          <Route path={PRIMARY_ROUTES.contact}>
            <ContactUs />
          </Route>
          <Route path={PRIMARY_ROUTES.initiatives.concat('/:name')}>
            <InitiativesPage />
          </Route>
          <Route path={PRIMARY_ROUTES.initiatives}>
            <InitiativesPage />
          </Route>
          <Route path={PRIMARY_ROUTES.events}>
            <EventsPage />
          </Route>
          <Route path={PRIMARY_ROUTES.careers}>
            <CareersPage />
          </Route>
          <Route path={PRIMARY_ROUTES.ascensionDay}>
            <AscensionDay />
          </Route>
          <Route path={PRIMARY_ROUTES.home}>
            <HomePage />
          </Route>
          <Route path={PRIMARY_ROUTES.team}>
            <TeamPage />
          </Route>
          <Route path={PRIMARY_ROUTES.spaceCampSocial}>
            <SpaceCampSocial />
          </Route>
          <Route path={'/'}>
            <RedirectWrapper to={PRIMARY_ROUTES.home} />
          </Route>
        </Switch>
        <PartnerBanner />
        <Footer />
      </div>
    </div>
  );
};

export default App;
