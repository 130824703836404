import React, { FunctionComponent, ReactElement } from 'react';
import CW from '../../assets/images/Portraits/Colt_Whittal_Profile.jpeg';
import MH from '../../assets/images/Portraits/Matt_Huston_Profile.png';
import { dayOne } from '../../common/Data/AscensionDayTopics';
import HorizontalPortrait from '../Portrait/HorizontalPortrait';
import VerticalPortrait from '../Portrait/VerticalPortrait';
import Schedule from './Schedule';
// import VirtualSignupLogo from '../../assets/images/OpenHouse/VirtualLrg.svg';
import NC from '../../assets/images/Portraits/Nicholas_Chaillan_Profile.jpeg';
import './AscensionDay.css';
// import InPersonSignupLogo from '../../assets/images/OpenHouse/inperson.lrg.svg';
// import { generateScrollToRefListenerFunction } from '../../common/Helpers/ScrollHelpers';

const HEADER_SIZE = 'xl:text-5xl lg:text-5xl md:text-5xl sm:text-5xl xs:text-4xl';
const FLEX_DIRECTION =
  'xl:flex-row lg:flex-row md:flex-col md:items-center sm:flex-col sm:items-center xs:flex-col xs:items-center';
const PADDING_X = 'xl:px-40 lg:px-8 md:px-8 sm:px-8 xs:px-8';
export const PADDING_Y = 'xl:py-20 lg:py-20 md:py-20 sm:py-8 xs:py-8';
const FULL_SCHEDULE_LINK = 'https://drive.google.com/file/d/1TKRGZu4jy1am3IZvs-tq0S5OGkqUOswm/view?usp=sharing';
// const ACTION_BUTTON =
//   'border-2 border-dark-mint p-2 text-center rounded-full hover:bg-dark-liteMint bg-dark-mint text-black font-normal';
const VERTICAL_PORTRAIT = 'xl:block lg:block md:hidden sm:hidden xs:hidden mt-8';
const HORIZONTAL_PORTRAIT = 'flex xl:hidden lg:hidden mt-8';
// const REGISTER_BUTTON = `${ACTION_BUTTON} mt-5 w-56 text-lg cursor-not-allowed opacity-50`;

const AscensionDay: FunctionComponent = (): ReactElement => {
  // const registerDiv = createRef<HTMLDivElement>();

  return (
    <div data-testid="ascension-day-hero" className="ascensionDayHero">
      <div className={`ascension-day-hero-banner banner-cover flex items-center pt-0 xs:pt-16 ${PADDING_X}`}>
        <div className="mb-5">
          <p className="font-roboto-mono text-center text-white xl:text-2xl lg:text-2xl md:text-xl sm:text-lg xs:text-base xs:text-left">
            July 14-15, 2021{' '}
          </p>
          <p className={`${HEADER_SIZE} text-white font-space-age xl:text-7xl lg:text-7xl xs:text-left font-base`}>
            Ascension
          </p>
          <p className="text-white font-space-age xl:text-7xl lg:text-7xl md:text-5xl sm:text-5xl xs:text-4xl xs:text-left font-base">
            Day 2.0
          </p>
          <p className="join-us text-white xl:text-2xl lg:text-2xl md:text-xl sm:text-lg xs:text-base xl:w-5/12 lg:w-3/5 md:w-3/5 sm:w-3/5 xs:w-5/6">
            Join us for a two day event that will equip Space Force Deltas with the information, tools, and language
            needed to successfully lead the digital transformation of the US Space Force.
          </p>
          {/* <button
            onClick={generateScrollToRefListenerFunction(registerDiv, 50)}
            className={`${ACTION_BUTTON} xl:w-72 lg:w-72 md:w-56 sm:w-56 xs:w-48 xl:text-lg lg:text-lg md:text-base sm:text-base xs:text-sm mt-10`}
          >
            REGISTER FOR EVENT
          </button> */}
        </div>
      </div>
      <div className={`text-white justify-center items-center flex flex-col w-full ${PADDING_Y} ${PADDING_X}`}>
        <p className={`${HEADER_SIZE} font-oswald font-light px-4`}>Featured Speakers</p>
        <div className={`flex xl:justify-between lg:justify-between w-full ${FLEX_DIRECTION}`}>
          <VerticalPortrait
            containerClass={VERTICAL_PORTRAIT}
            img={NC}
            name="NICOLAS M. CHAILLAN"
            title="Chief Software Officer"
            org="US Air Force"
          />
          <HorizontalPortrait
            containerClass={HORIZONTAL_PORTRAIT}
            img={NC}
            name="NICOLAS M. CHAILLAN"
            title="Chief Software Officer"
            org="US Air Force"
          />
          <VerticalPortrait
            containerClass={VERTICAL_PORTRAIT}
            img={CW}
            name="COLT WHITTALL"
            title="Chief Experience Officer"
            org="US Air Force"
          />
          <HorizontalPortrait
            containerClass={HORIZONTAL_PORTRAIT}
            img={CW}
            name="COLT WHITTALL"
            title="Chief Experience Officer"
            org="US Air Force"
          />
          <VerticalPortrait
            containerClass={VERTICAL_PORTRAIT}
            img={MH}
            name="MATTHEW HUSTON"
            title="Chief Information Officer"
            org="Platform One"
          />
          <HorizontalPortrait
            containerClass={HORIZONTAL_PORTRAIT}
            img={MH}
            name="MATTHEW HUSTON"
            title="Chief Information Officer"
            org="Platform One"
          />
        </div>
      </div>
      <div className={`${PADDING_Y} text-white w-full flex flex-col justify-center bg-dark-mirage`}>
        <p className={`${HEADER_SIZE} mx-auto text-left mb-4 font-oswald text-dark-mint font-light`}>Session Topics</p>
        <Schedule {...dayOne} />
        <a
          href={FULL_SCHEDULE_LINK}
          target="__blank__"
          className="mx-auto border-2 border-dark-mint p-2 mt-8 w-64 text-lg text-center bg-transparent rounded-full hover:bg-dark-mint hover:text-black"
        >
          SEE FULL SCHEDULE HERE
        </a>
      </div>
      {/* <div
        ref={registerDiv}
        className={`text-white justify-center items-center flex flex-col w-full ${PADDING_X} ${PADDING_Y}`}
      >
        <p className={`${HEADER_SIZE} font-oswald px-4 font-light`}>Two Ways to Join</p>
        <div
          className={`flex w-full mt-8 ${FLEX_DIRECTION} justify-center items-center font-light text-lg text-center`}
        >
          <div className={`flex flex-col items-center justify-center `}>
            <p>In Person</p>
            <img src={InPersonSignupLogo} alt="In Person Signup Log" />
            <button disabled className={REGISTER_BUTTON}>
              REGISTRATION CLOSED
            </button>
          </div>
          <div>
            <p className={`${HEADER_SIZE} font-space-age mx-32 my-16 text-5xl`} style={{ opacity: 1 }}>
              OR
            </p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <p>Virtual</p>
            <img src={VirtualSignupLogo} alt="Virtual Sign Up" />
            <button disabled className={REGISTER_BUTTON}>
              REGISTRATION CLOSED
            </button>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default AscensionDay;
