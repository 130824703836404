import React, { FunctionComponent, ReactElement } from 'react';

export interface DataSnapshotProps {
  number: string;
  phrase: string;
}

const DataSnapshot: FunctionComponent<DataSnapshotProps> = (props): ReactElement => {
  return (
    <div className="text-white items-center flex flex-col mb-6">
      <p className="xl:text-8xl lg:text-7xl md:text-4.5xl sm:text-5xl xs:text-4xl">{props.number}</p>
      <p className="xl:text-2xl lg:text-xl md:text-base sm:text-xl xs:text-lg uppercase">{props.phrase}</p>
    </div>
  );
};

export default DataSnapshot;
