import React, { FunctionComponent } from 'react';
import Hero from '../Hero/Hero';
import Innovation from './Innovation/Innovation';
import InitiativeCarousel from './InitiativeCarousel/InitiativeCarousel';
import BannerImage from '../../assets/images/HomeBanner.png';
import Claim from '../Claim/Claim';
import DataSnapshot from './DataSnapshot/DataSnapshot';
import './HomePage.css';

export const HOME_TITLE = 'THE PREMIER SOFTWARE FACTORY FOR SPACE FORCE';

export const HomePage: FunctionComponent = () => {
  return (
    <div>
      <Hero image={BannerImage} title={HOME_TITLE} />
      <Claim
        claim="Transforming the Digital Force"
        description="We specialize in developing custom technology solutions for the US Space Force. Our team is dedicated to the vision of building a digital service and has the expertise to deliver software that ensures our Guardians remain interconnected, innovative, and digitally dominant."
      />
      <InitiativeCarousel />
      <div
        className="data-background-image flex justify-between xl:flex-row lg:flex-row md:flex-row sm:flex-col xs:flex-col xl:px-64 lg:px-20 md:px-16 xl:py-40 lg:py-28 md:py-20 sm:py-5 xs:py-4 xs:bg-homeData ml-auto mr-auto"
        style={{ maxWidth: '2200px' }}
      >
        <DataSnapshot number="100+" phrase="employees" />
        <DataSnapshot number="12" phrase="products with ctf" />
        <DataSnapshot number="10" phrase="sbirs supported" />
        <DataSnapshot number="750+" phrase="visitors since 2018" />
      </div>
      <Innovation />
      <iframe
        className="video-iframe ml-auto mr-auto xl:my-20 lg:my-20 md:my-20 sm:my-8 xs:my-8"
        src="https://www.youtube.com/embed/tvIslsHLJ18"
        frameBorder="0"
        title="Space CAMP: Premier Software Factory for the US Space Force"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default HomePage;
