import { RefObject } from 'react';
import { sleep } from './Sleep';

/* eslint-disable @typescript-eslint/no-explicit-any */

export const scrollToRef = (ref: RefObject<any> | null, offset = 0) => {
  if (ref && ref.current) {
    window.scrollTo({
      top: ref.current.offsetTop - offset,
      behavior: 'smooth'
    });
  }
};

export const generateScrollToRefListenerFunction = (ref: RefObject<any> | null, offset = 0): (() => void) => {
  return () => scrollToRef(ref, offset);
};

export const generateScrollToContactDispatcher = (): (() => void) => {
  return () => document.dispatchEvent(new CustomEvent('scrollToContact'));
};

export const sleepThenScrollToRef = async (
  refKey: string | undefined,
  refs: Map<string, RefObject<any> | null>,
  setRefKey: (arg: string | undefined) => void,
  sleepMS = 500
) => {
  if (!refKey) return;
  const ref = refs.get(refKey);
  if (ref && ref.current) {
    await sleep(sleepMS);
    scrollToRef(ref, 80);
  }
  setRefKey(undefined);
};
