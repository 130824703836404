import React, { FunctionComponent, ReactElement } from 'react';
import TeamHorizontalPortrait from '../Portrait/TeamHorizontalPortrait';
import TeamVerticalPortrait from '../Portrait/TeamVerticalPortrait';
import { TeamMembers } from './TeamMembers';

const HEADER_SIZE = 'xl:text-5xl lg:text-5xl md:text-5xl sm:text-5xl xs:text-4xl';
const FLEX_DIRECTION =
  'xl:flex-row lg:flex-row md:flex-col md:items-center sm:flex-col sm:items-center xs:flex-col xl:justify-center lg:justify-evenly xs:items-center';
const PADDING_X = 'xl:px-8 lg:px-8 md:px-8 sm:px-8 xs:px-8';
const PADDING_Y = 'xl:py-20 lg:py-20 md:py-20 sm:py-8 xs:py-8';
const VERTICAL_PORTRAIT = 'xl:block lg:block md:block sm:hidden xs:hidden mt-8';
const HORIZONTAL_PORTRAIT = 'flex xl:hidden lg:hidden md:hidden mt-8';

export const TeamRoster: FunctionComponent = (): ReactElement => {
  return (
    <div className={`text-white flex flex-col w-full justify-center items-center ${PADDING_Y} ${PADDING_X}`}>
      <p className={`${HEADER_SIZE} font-oswald font-light px-4 md:mb-16 xs:mb-0`}>CAMP Staff Leads</p>
      <div className={`flex flex-wrap ${FLEX_DIRECTION}`} style={{ maxWidth: '1920px' }}>
        {TeamMembers.map((teamMember, index) => (
          <div key={`portrait-${index}`} className={'ml-4 mr-4'}>
            <TeamVerticalPortrait
              key={`team-member-${index}-vertical-portrait`}
              containerClass={VERTICAL_PORTRAIT}
              {...teamMember}
            />
            <TeamHorizontalPortrait
              key={`team-member-${index}-horizontal-portrait`}
              containerClass={HORIZONTAL_PORTRAIT}
              {...teamMember}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamRoster;
