export const PRIMARY_ROUTES = {
  home: '/home',
  initiatives: '/initiatives',
  process: '/process',
  work: '/work',
  events: '/events',
  careers: '/careers',
  contact: '/contact',
  ascensionDay: '/ascensionday',
  spaceCampSocial: '/spacecampsocial',
  team: '/team'
};
