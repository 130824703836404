import React, { FunctionComponent } from 'react';
import SocialMediaBar from '../SocialMediaBar/SocialMediaBar';
import ConnectWithUs from './ConnectWithUs';
import EngageWithUs from './EngageWithUs';
import WeAreSpaceCAMP from './WeAreSpaceCAMP';
import './Footer.css';

type FooterClasses = {
  innerWidth: string;
  section: string;
};

const useCommonClasses = (): FooterClasses => ({
  innerWidth: 'xl:w-3/4 lg:w-3/4 md:w-3/4 sm:w-3/4 xs:w-3/4',
  section: `xl:flex
    lg:flex
    md:flex
    sm:flex
    xl:flex-col
    lg:flex-col
    md:flex-col
    sm:flex-col
    xl:text-xl
    lg:text-xl
    md:text-xl
    sm:text-lg
    xs:text-lg`
});

const Footer: FunctionComponent = () => {
  const { innerWidth, section } = useCommonClasses();
  return (
    <div
      className="h-auto
        pt-20
        pb-10
        footerWrapper
        bg-dark-mirage 
        flex 
        flex-col 
        items-center
        justify-center
        w-full"
    >
      <div
        className={`${innerWidth}
          flex
          lg:flex
          md:flex
          sm:flex
          xl:flex-row
          lg:flex-row
          md:flex-row
          sm:flex-row
          xs:flex-col
          sm:space-y-0
          xs:space-y-8
          `}
      >
        <div
          className={`text-white
            font-light
            we-are-spacecamp
            xl:w-1/2
            lg:w-2/5
            sm:w-full
            sm:items-start
            xs:w-full
            xs:items-center
            ${section}
           `}
        >
          <WeAreSpaceCAMP />
        </div>

        <div
          className={`
            text-white
            font-light
            border-solid
            border-r-2
            border-l-2
            border-tertiary-500
            engage-with-us
            xl:w-1/3
            xl:items-center
            lg:w-1/4
            lg:items-center
            sm:w-full
            ${section}
            xs:hidden
          `}
        >
          <EngageWithUs />
        </div>
        <div
          className="connect-with-us flex flex-col
            md:w-2/12
            sm:w-1/4
            xs:w-full
            xl:items-end
            xs:items-start"
        >
          <ConnectWithUs />
        </div>
      </div>

      <div className={`${innerWidth} h-1 bg-dark-mint mt-2 mb-6 rounded-lg`}></div>
      <div className={`${innerWidth} flex xl:flex-row lg:flex-row md:flex-row sm:flex-col xs:flex-col justify-between`}>
        <div className="flex flex-col text-sm sm:text-xs xs:text-xs text-white xl:mb-0 lg:mb-0 md:mb-6 sm:mb-8 xs:mb-6 font-light">
          <p>© 2020 SpaceCAMP, All rights reserved. </p>
          {/* <p>Terms of Use | Policy</p> */}
        </div>
        <SocialMediaBar containerClass="space-x-8 xl:w-1/4 lg:w-2/5 md:w-2/5 sm:w-full xs:w-full justify-end" />
      </div>
    </div>
  );
};

export default Footer;
