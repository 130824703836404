import React, { FunctionComponent, ReactElement } from 'react';
import './Tag.css';

export interface TagProps {
  text: string;
  bgColor: string;
  bgOpacity: string;
}

const Tag: FunctionComponent<TagProps> = (props): ReactElement => {
  return (
    <div
      data-testid="tag-component"
      className={`tag-main mr-4 rounded-full py-auto flex justify-center bg-${props.bgColor}`}
    >
      <div
        className={`truncate flex flex-col justify-center px-3 h-full rounded-full bg-white bg-opacity-${props.bgOpacity} text-sm text-white text-opacity-87`}
      >
        {props.text}
      </div>
    </div>
  );
};

export default Tag;
