import React, { ReactNode, useMemo, useState } from 'react';
import { ValidEvent } from '../Interfaces/EventInfo';

type SetEventsInterface = (pastEvents: ValidEvent[], upcomingEvents: ValidEvent[]) => void;

export interface EventsContextInterface {
  state: 'new' | 'hasValue';
  past: ValidEvent[];
  future: ValidEvent[];
  setEvents: SetEventsInterface;
}

const defaultValues: EventsContextInterface = {
  state: 'new',
  past: [],
  future: [],
  setEvents: () => {
    // do nothing
  }
};

export const EventsContext = React.createContext<EventsContextInterface>(defaultValues);

export const EventsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [events, setEvents] = useState<EventsContextInterface>(defaultValues);

  const newValue = useMemo(() => {
    const setEventsHandler: SetEventsInterface = (pastEvents: ValidEvent[], upcomingEvents: ValidEvent[]) => {
      setEvents({ state: 'hasValue', past: pastEvents, future: upcomingEvents, setEvents: events.setEvents });
    };

    return { ...events, setEvents: setEventsHandler };
  }, [events]);

  return <EventsContext.Provider value={newValue}>{children}</EventsContext.Provider>;
};
