import GJ from '../../../assets/images/Portraits/Garza_Jesus_2.png';
import HS from '../../../assets/images/Portraits/Hart_Skyler_2.png';
import KP from '../../../assets/images/Portraits/Kitchens_Patrick_2.png';
import LP from '../../../assets/images/Portraits/Lorigan_Patrick_2.png';
import PS from '../../../assets/images/Portraits/Pulscher_Simon_2.png';
import RL from '../../../assets/images/Portraits/Rodriguez_Laura_2.png';
import SJ from '../../../assets/images/Portraits/Sakamoto_Jachin_2.png';
import SG from '../../../assets/images/Portraits/Scheller_Geoffrey_2.png';
import SS from '../../../assets/images/Portraits/Starnes_Sean_2.png';
import DG from '../../../assets/images/Portraits/Deng_Gordon_1.png';
import GE from '../../../assets/images/Portraits/Gartzke_Erik_2.png';
import PR from '../../../assets/images/Portraits/Patel_Rishi_2.png';
import SV from '../../../assets/images/Portraits/Sheffey_Vince_2.png';
import CJ from '../../../assets/images/Portraits/Christopher_Jarhym_2.png';
import EL from '../../../assets/images/Portraits/Enders_Lauren_2.png';
import FH from '../../../assets/images/Portraits/Finley_Hanson_2.png';
import HJ from '../../../assets/images/Portraits/Holly_Jesse_2.png';
import KM from '../../../assets/images/Portraits/Kamal_Murtaza_2.png';
import MJ from '../../../assets/images/Portraits/Musselman_Jacob_2.png';
// import Placeholder from '../../../assets/images/Portraits/Not_Pictured.png'; // Used when we don't have someone's picture

export const TeamMembers: { img: string; name: string; title: string }[] = [
  { img: SJ, name: 'Lt Col Jachin Sakamoto', title: 'Branch Chief' },
  { img: LP, name: 'Mr Patrick Lorigan', title: 'Technical Director' },
  { img: KM, name: 'Mr Murtaza Kamal', title: 'Chief Data Architect' },
  { img: GE, name: 'Mr Erik Gartzke', title: 'Chief Experience Officer' },
  { img: SS, name: 'Mr Sean Starnes', title: 'Chief Information Security Officer' },
  { img: EL, name: 'Lauren Enders', title: 'Acquisitions & Strategy Lead' },
  { img: HJ, name: 'Mr Jesse Holly', title: 'Business Manager' },
  { img: SG, name: 'Mr Geoffrey Scheller', title: 'Facility Asset Manager' },
  { img: FH, name: 'Hanson Finley', title: 'Catalyst Accelerator DoD Lead' },
  { img: KP, name: 'Mr Patrick Kitchens', title: 'Systems Portfolio Lead' },
  { img: HS, name: 'Capt Skyler Hart', title: 'Operations Portfolio Lead' },
  { img: PR, name: 'Capt Rishi Patel', title: 'JCO Portfolio Lead' },
  { img: SV, name: 'Mr Vince Sheffey', title: 'Deputy JCO Porfolio Lead' },
  { img: PS, name: 'Mr Simon Pulshcer', title: 'JCO Operations Interface' },
  { img: DG, name: 'Mr Gordon Deng', title: 'Agile Summit Portfolio Lead' },
  { img: GJ, name: 'Mr Jesus Garza', title: 'DoD Software Developer' },
  { img: MJ, name: 'Capt Jacob Musselman', title: 'Gravity Portfolio Lead' },
  { img: RL, name: 'Mrs Laura Rodriguez', title: 'Gravity Portfolio Deputy Lead' },
  { img: CJ, name: 'Jarhym Christopher', title: 'DoD Software Developer' }
];
