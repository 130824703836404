import React, { FunctionComponent, ReactElement } from 'react';
import { PADDING_Y } from '../AscensionDay/AscensionDay';

export interface SpaceDomainApplicationsProps {
  title: string;
  description: string;
  wrapperClass?: string;
}

const SpaceDomainApplications: FunctionComponent<SpaceDomainApplicationsProps> = (props): ReactElement => {
  const wrapperClass = props.wrapperClass ? props.wrapperClass : `bg-dark-mirage w-full ${PADDING_Y}`;
  return (
    <div className={`${wrapperClass}`}>
      <p className="text-center text-dark-mint xl:text-5xl lg:text-5xl md:text-4xl sm:text-3xl xs:text-3xl font-oswald">
        {props.title}
      </p>
      <p className="pt-4 xl:text-3xl lg:text-2xl md:text-2xl sm:text-lg xs:text-lg xl:mx-72 lg:mx-16 md:mx-32 sm:mx-8 xs:mx-8 text-center font-light text-white">
        {props.description}
      </p>
    </div>
  );
};

export default SpaceDomainApplications;
