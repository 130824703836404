import React, { FunctionComponent, ReactElement } from 'react';
import DownArrow from '../../assets/icons/DownArrow.svg';
import LinkIcon from '../../assets/icons/LinkIcon.svg';
import { PADDING_Y } from '../AscensionDay/AscensionDay';
import InternalOrExternalLink from '../InternalOrExternalLink/InternalOrExternalLink';
import './ProductCard.css';

export interface ProductCardProps {
  title: string;
  description: string | string[];
  image: string;
  linkHeader?: string;
  linkText?: string;
  linkURL?: string;
  wrapperClass?: string;
  cardRef?: React.LegacyRef<HTMLDivElement>;
  showArrow?: boolean;
  arrowClick?: () => void;
}

const ProductCard: FunctionComponent<ProductCardProps> = (props): ReactElement => {
  const rendersProjectURL = () => {
    if (props.linkURL && props.linkHeader && props.linkText) {
      return (
        <div>
          <div>{props.linkHeader}</div>
          <div key={`${props.linkURL}:link`} className="flex">
            <img src={LinkIcon} alt="link" className="mb-2" />
            <InternalOrExternalLink
              url={props.linkURL}
              text={props.linkText}
              classString="ml-2 underline text-sm text-white text-opacity-74 leading-5 hover:underline mb-2"
            />
          </div>
        </div>
      );
    }
  };

  return (
    <div ref={props.cardRef} className={`projectCard w-full flex flex-col ${props.wrapperClass} ${PADDING_Y}`}>
      <div className="flex xl:flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col items-center">
        <div className="h-full xl:w-3/5 lg:w-3/5 md:w-full sm:w-full xs:w-full xl:mb-8 lg:m-0 md:m-0 sm:m-0 xs:m-0 pl-32 pr-6 flex items-center">
          {props.image.startsWith('http') ? (
            <div className="resp-container w-full">
              <iframe
                className="resp-iframe"
                src={props.image}
                frameBorder="0"
                title={props.title}
                scrolling="no"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          ) : (
            <img src={props.image} alt={`${props.title} LOGO`} className="w-full" />
          )}
        </div>
        <div className="text-white xl:w-2/5 lg:w-2/5 md:w-full sm:w-full xs:w-full xl:p-0 xl:mb-8 lg:p-0 lg:mb-8 md:px-8 sm:px-8 xs:px-8 pt-8 xl:justify-around">
          <p className="xl:text-5xl lg:text-4xl md:text-4xl sm:text-2xl xs:text-2xl xl:m-0 lg:m-0 font-oswald">
            {props.title}
          </p>
          <div className="xl:text-xl lg:text-xl md:text-xl sm:text-base xs:text-base text-left xl:mt-8 xl:mr-32 lg:mt-8 lg:mr-16">
            {!Array.isArray(props.description) && props.description}
            {Array.isArray(props.description) &&
              props.description.map((description, descriptionIndex) => {
                return (
                  <div key={`${props.title}:${descriptionIndex}`}>
                    <p>{description}</p>
                    <br />
                  </div>
                );
              })}
          </div>
          {props.linkHeader !== undefined ? rendersProjectURL() : ''}
        </div>
      </div>
      <div
        onClick={props.arrowClick as React.MouseEventHandler<HTMLDivElement>}
        title="Move to next."
        className={`${
          !props.showArrow ? 'hidden' : 'xl:block lg:block hidden'
        } nextCardArrow cursor-pointer ml-auto mr-auto rounded-full pb-4 pt-6 h-20 w-20 flex items-center justify-center`}
      >
        <img src={DownArrow} alt="DownArrowIcon" />
      </div>
    </div>
  );
};

export default ProductCard;
