import React, { FunctionComponent, ReactElement } from 'react';
import DownArrowIcon from '../../assets/icons/DownArrow.svg';
import LinkIcon from '../../assets/icons/LinkIcon.svg';
import { InitiativeLink } from '../../common/Data/InitiativePageResources';
import { PADDING_Y } from '../AscensionDay/AscensionDay';
import InternalOrExternalLink from '../InternalOrExternalLink/InternalOrExternalLink';

export interface InitiativeCardProps {
  title: string;
  description: string;
  image: string;
  links: InitiativeLink[];
  name: string;
  wrapperClass?: string;
  cardRef?: React.LegacyRef<HTMLDivElement>;
  showArrow?: boolean;
  arrowClick?: () => void;
}

const InitiativeCard: FunctionComponent<InitiativeCardProps> = (props): ReactElement => {
  return (
    <div
      ref={props.cardRef}
      className={`w-full flex flex-col justify-around ${props.wrapperClass} ${PADDING_Y} xl:h-initiativeCardHeight lg:h-initiativeCardHeight`}
    >
      <div className="flex xl:flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col justify-center items-center">
        <div className="text-white xl:w-17/50 lg:w-1/3 w-full xl:p-0 xl:mb-8 lg:p-0 lg:mb-8 md:px-8 sm:px-8 xs:px-8 pt-8 xl:justify-around">
          <p className="xl:text-5xl lg:text-4xl md:text-4xl sm:text-2xl xs:text-2xl xl:m-0 lg:m-0 font-oswald text-white text-opacity-87">
            {props.title}
          </p>
          <p className="xl:text-2xl lg:text-xl md:text-xl sm:text-base xs:text-base text-left my-8 text-white text-opacity-74 leading-8">
            {props.description}
          </p>
          {props.children}
          {props.links.length > 0 && (
            <>
              <p className={`text-xl mb-1`}>{props.name} Applications</p>
              {props.links.map(l => {
                return (
                  <div key={`${l.url}:link`} className="flex">
                    <img src={LinkIcon} alt="link" className="mb-2" />
                    <InternalOrExternalLink
                      url={l.url}
                      text={l.text}
                      classString="ml-2 underline text-sm text-white text-opacity-74 leading-5 hover:underline mb-2"
                    />
                  </div>
                );
              })}
            </>
          )}
        </div>
        <div className="h-full xl:w-1/3 lg:w-9/20 md:w-full sm:w-full xs:w-full flex xl:justify-end lg:justify-end justify-center xl:mt-0 lg:mt-0 mt-8 items-center">
          <img src={props.image} alt={`${props.title} LOGO`} className="w-3/4" />
        </div>
      </div>
      <div
        data-testid="next-card-arrow"
        onClick={props.arrowClick as React.MouseEventHandler<HTMLDivElement>}
        title="Move to next."
        className={`${
          !props.showArrow ? 'hidden' : 'xl:block lg:block hidden'
        } nextCardArrow cursor-pointer ml-auto mr-auto rounded-full pb-4 pt-6 h-20 w-20 flex items-center justify-center`}
      >
        <img src={DownArrowIcon} alt="DownArrowIcon" />
      </div>
    </div>
  );
};

export default InitiativeCard;
