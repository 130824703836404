export interface WhatMakesUsDifferent {
  title: string;
  description: string;
}

export const ourDifferences: WhatMakesUsDifferent[] = [
  {
    title: 'Agile',
    description:
      'Our commitment to Agile principles drives rapid innovation and development. Agile allows us to adapt and innovate quickly to address the dynamic mission and tactical needs of operators in the space domain. We build our products in short iterative cycles, validating our assumptions and including our partners in each step of the process, from discovery through deployment.'
  },
  {
    title: 'Balanced Teams',
    description:
      'We practice balanced teams, a multi-disciplinary collaborative process that equips teams with the tools to appropriately balance customer, technical, and user needs. Each team is lead by a trio, including a User Experience Designer, Developer, and Product Manager, who work together to ensure the product meets all of the constraints and needs of the mission operation.'
  },
  {
    title: 'Extreme Programming',
    description:
      'We embrace XP, an opinionated flavor of Agile software development that encourages rapid feedback, simplicity, embracing change, and quality work. Our developers ensure quality and security through Test Driven Development and Pair Programming. These practices enable our teams to rapidly build, test, and deploy reliable code to the operators.'
  },
  {
    title: 'User Centered Design',
    description:
      'We apply User Centered Design (UCD) to everything we do. UCD is an iterative design process that involves users throughout the development cycle via a variety of research and design techniques. Using UCD, we create software that meets the needs of space operators while ensuring it is a solution that they actually want to use.'
  },
  {
    title: 'CI/CD',
    description:
      'Our teams build and manage Continuous Integration Continuous Deployment (CI/CD) pipelines for each product through Platform One. These pipelines enable teams to automatically test and release updates directly to the live production environment at all security levels. This process streamlines the deployment, ensuring our software is always up to date and evolves to meets the space operators needs in real time.'
  },
  {
    title: 'Design Thinking',
    description:
      'Our teams follow the Design Thinking process to solve wicked problems. Design Thinking is a non-linear, iterative process that teams use to empathize with users, challenge assumptions, define problems, and create innovative solutions to prototype and test. This process is perfect for addressing the dynamic problems of space warfare, where solutions are needed at the speed of the fight.'
  }
];
