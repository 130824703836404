import React, { FunctionComponent, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import SACT from '../../../assets/images/SACT.png';
import { PRIMARY_ROUTES } from '../../../common/Routes/PrimaryRoutes';
import { PADDING_Y } from '../../AscensionDay/AscensionDay';
import './CallToAction.css';

const HEADER = 'Ready To Make a Difference?';
const CALL_DESCRIPTION = `Timely and effective adaptation to the breakneck pace of the Information Age will not be achieved via the status quo. Are you ready to challenge the way we do business and how we provide mission-enhancing capabilities to operators in the DoD Space Domain? Want to make a real difference in shaping the future of software in the US Space Force? Learn more about how you can get involved.`;
const TAKE_ACTION_TEXT = 'SPACE CAMP CAREERS';

export const CallToAction: FunctionComponent = (): ReactElement => {
  return (
    <div className={`flex xl:flex-row xs:flex-col xl:justify-between background-slate-custom`}>
      <div
        className={`text-white w-full xl:w-1/2 xs:mx-auto xl:my-auto md:max-w-xl xs:max-w-lg xl:pl-4 sm:px-0 xs:px-8 ${PADDING_Y}`}
      >
        <p className="xl:text-4.75xl lg:text-3xl md:text-3xl sm:text-xl xs:text-xl xl:text-left xs:text-center font-oswald text-opacity-87">
          {HEADER}
        </p>
        <p className="xl:text-base lg:text-lg md:text-base sm:text-base xs:text-base xl:text-left xs:text-center mt-6 text-opacity-74">
          {CALL_DESCRIPTION}
        </p>
        <p className={'py-2 xs:mt-2 md:mt-6 xl:block xs:flex xl:justify-left xs:justify-center'}>
          <Link
            className={`shadow-md border-2 border-dark-mint text-center text-dark-mint rounded-full px-6 py-2 xl:ml-0 lg:ml-0 xl:mt-0 lg:mt-0 md:mt-2 sm:mt-2 xs:mt-2`}
            title="To careers page."
            to={PRIMARY_ROUTES.careers}
          >
            {TAKE_ACTION_TEXT}
          </Link>
        </p>
      </div>
      <div className={`w-full xl:w-1/2`}>
        <img src={SACT} alt="Residency Program" className="residency-program" />
      </div>
    </div>
  );
};

export default CallToAction;
