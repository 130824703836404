import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { PRIMARY_ROUTES } from '../../common/Routes/PrimaryRoutes';

/* eslint-disable @typescript-eslint/no-explicit-any */

const engageOptionLabels = [
  { label: 'Home', id: 'homeFooterLink', routeKey: 'home' },
  { label: 'Events', id: 'eventsFooterLink', routeKey: 'events' },
  { label: 'Initiatives', id: 'initiativesFooterLink', routeKey: 'initiatives' },
  { label: 'Careers', id: 'careersFooterLink', routeKey: 'careers' },
  { label: 'Process', id: 'processFooterLink', routeKey: 'process' },
  { label: 'Contact Us', id: 'contactUsFooterLink', routeKey: 'contact' }
];

const EngageWithUs: FunctionComponent = () => (
  <div className="flex flex-col">
    <p
      className="text-dark-mint 
          font-normal 
          xl:text-2xl 
          lg:text-xl 
          md:text-xl 
          sm:text-lg 
          xs:text-base
          text-left
          mb-2
          "
    >
      Engage With Us
    </p>
    <div
      className="grid 
          xl:grid-cols-2
          lg:grid-cols-1
          md:grid-cols-1
          sm:grid-cols-1
          xl:gap-x-20
          xl:gap-y-0
          lg:gap-y-5
          sm:gap-y-5
          "
    >
      {engageOptionLabels.map(({ label, id, routeKey }, optionIndex) => (
        <p key={`${id}:${optionIndex}`}>
          <Link
            id={id}
            className="font-light sm:text-sm border-b-4 border-solid border-transparent hover:border-dark-mint"
            to={(PRIMARY_ROUTES as any)[routeKey]}
          >
            {label}
          </Link>
        </p>
      ))}
      <p>
        <Link
          id="workFooterLink"
          className="font-light sm:text-sm border-b-4 border-solid border-transparent hover:border-dark-mint"
          to={PRIMARY_ROUTES.work}
        >
          Work
        </Link>
      </p>
      <p>
        <a
          id="swagStoreFooterLink"
          className="font-light sm:text-sm border-b-4 border-solid border-transparent hover:border-dark-mint"
          href="https://teamlocker.squadlocker.com/#/lockers/space-camp"
        >
          Swag Store
        </a>
      </p>
    </div>
  </div>
);

export default EngageWithUs;
