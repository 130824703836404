import React, { FunctionComponent } from 'react';
import { HOME_TITLE } from '../HomePage/HomePage';
import './Hero.css';

export interface HeroProps {
  image: string;
  title: string;
  classString?: string;
}

export const Hero: FunctionComponent<HeroProps> = props => {
  return (
    <div
      className={`banner-cover flex items-center justify-center ${props.classString}`}
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${props.image})`
      }}
    >
      <div className="px-5">
        <p
          className={`text-white xl:text-6xl lg:text-5xl md:text-4xl sm:text-4xl xs:text-4xl font-base ${
            props.title === HOME_TITLE ? 'font-oswald uppercase' : 'font-space-age capitalize'
          } `}
        >
          {props.title}
        </p>
      </div>
    </div>
  );
};

export default Hero;
