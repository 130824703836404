import React, { FunctionComponent, ReactElement } from 'react';
import Slider from 'react-slick';
import '../../../../node_modules/slick-carousel/slick/slick-theme.css';
import '../../../../node_modules/slick-carousel/slick/slick.css';
import LeftArrowIcon from '../../../assets/icons/LeftArrow.svg';
import RightArrowIcon from '../../../assets/icons/RightArrow.svg';
import { Initiatives } from '../../../common/Data/InitiativePageResources';
import { PADDING_Y } from '../../AscensionDay/AscensionDay';
import LeftArrow from '../../CarouselArrows/LeftArrow';
import RightArrow from '../../CarouselArrows/RightArrow';
import InitiativeCarouselCard from '../InitiativeCarouselCard/InitiativeCarouselCard';
import './InitiativeCarousel.css';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  focusOnSelect: true,
  centerMode: true,
  arrows: true,
  nextArrow: (
    <RightArrow>
      <img src={RightArrowIcon} alt="RightArrowIcon" />
    </RightArrow>
  ),
  prevArrow: (
    <LeftArrow>
      <img src={LeftArrowIcon} alt="LeftArrowIcon" />
    </LeftArrow>
  ),
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        centerMode: true
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        centerMode: false
      }
    }
  ]
};

const InitiativeCarousel: FunctionComponent = (): ReactElement => {
  return (
    <div className={`xl:px-20 px-10 ml-auto mr-auto ${PADDING_Y} mb-6 initiativeCarousel`}>
      <p className="text-center text-white xl:text-5xl lg:text-5xl md:text-4xl sm:text-3xl xs:text-3xl font-oswald">
        Our Services
      </p>
      <p className="pt-4 xl:text-3xl lg:text-2xl md:text-2xl sm:text-lg xs:text-lg xl:mx-3/4 lg:mx-16 md:mx-8 sm:mx-2 xs:mx-2 text-center font-light text-white">
        Our team offers a full range of technology services to the Space Force Deltas, Combat Development Teams, Air
        Force and other DoD partners. Through each of our core initiatives, we deliver industry level solutions that
        help your teams solve their most challenging problems.
      </p>
      <div data-testid="carousel-wrapper" className="ml-6 mr-8">
        <Slider {...settings}>
          {Initiatives.map((card, cardIndex) => (
            <InitiativeCarouselCard key={`${cardIndex}`} {...card} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default InitiativeCarousel;
