import afSoftware from '../../assets/images/partners/AFSoftware.png';
import pOne from '../../assets/images/partners/P1.png';
import USSF from '../../assets/images/partners/USSF.png';
import jtfSpaceDefense from '../../assets/images/partners/JTFSpaceDefense.png';
import officeOfSpaceCommerce from '../../assets/images/partners/OfficeOfSpaceCommerce.png';
import ussfChiefDataOffice from '../../assets/images/partners/USSFChiefDataOffice.png';
import NSDC from '../../assets/images/partners/NSDC.png';

export interface Partner {
  img: string;
  name: string;
  url: string;
}

export const Partners: Partner[] = [
  {
    name: 'USSF Deltas',
    img: USSF,
    url: 'https://www.spaceforce.mil/'
  },
  {
    name: 'AF Software Factory',
    img: afSoftware,
    url: 'https://software.af.mil/'
  },
  {
    name: 'JTF Space Defense',
    img: jtfSpaceDefense,
    url: 'https://www.schriever.spaceforce.mil/'
  },
  {
    name: 'Platform One',
    img: pOne,
    url: 'https://p1.dso.mil/#/'
  },
  {
    name: 'NSDC',
    img: NSDC,
    url: 'https://www.schriever.spaceforce.mil/'
  },
  {
    name: 'USSF Chief Data Office',
    img: ussfChiefDataOffice,
    url: '' // TODO: Get url
  },
  {
    name: 'Office of Space Commerce',
    img: officeOfSpaceCommerce,
    url: 'https://www.space.commerce.gov/'
  }
];
