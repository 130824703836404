import React, { FunctionComponent } from 'react';
import SpaceCampLogoAndTitle from '../../assets/images/Logos/SpaceCampLogoWithTitle-stacked.svg';
import SpaceForceLogo from '../../assets/images/Logos/USSF-Logo-Vertical-170px.svg';
import './Footer.css';

const WeAreSpaceCAMP: FunctionComponent = () => (
  <div className="flex xl:flex-row xs:flex-col xl:space-x-8 lg:space-y-0 xs:space-y-8">
    <img src={SpaceCampLogoAndTitle} alt="spacecamp logo footer" className="h-40" />
    <div className="flex flex-col">
      <p
        id="footerWeAreSpaceCAMP"
        className="text-dark-mint
            font-normal
            xl:text-2xl
            lg:text-xl
            md:text-xl
            sm:text-lg
            xs:text-2xl
            mb-2
            "
      >
        We Are Space CAMP
      </p>
      <p className="text-justify mb-2 sm:text-sm">
        Space CAMP is an AFRL software factory focused on the continuous development and deployment of USSF mission
        applications to operators in the DoD Space Community.
      </p>
      <span
        className="text-dark-mint font-thin
        flex flex-row flex-wrap justify-between sm:text-sm"
      >
        <span>#spacecamp</span>
        <span>#ussf</span>
        <span>#afrl</span>
      </span>
    </div>
    <img src={SpaceForceLogo} alt="space force logo footer" className="h-40 sm:block xs:hidden" />
  </div>
);

export default WeAreSpaceCAMP;
