import React, { FunctionComponent, ReactElement, useState } from 'react';
import { PortraitProps } from './TeamPortrait.props';

const TeamVerticalPortrait: FunctionComponent<PortraitProps> = (props): ReactElement => {
  const [containerWidth, setContainerWidth] = useState(360);
  const [containerHeight, setContainerHeight] = useState(455);
  const scaleFactor = 1.05;

  return (
    <div
      style={{
        width: `${360 * scaleFactor}px`,
        height: `${455 * scaleFactor}px`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div
        data-testid="vertical-portrait-scaling-container"
        className={`${props.containerClass}`}
        style={{
          width: `${containerWidth}px`,
          height: `${containerHeight}px`,
          cursor: 'pointer'
        }}
        onMouseEnter={() => {
          const scaledUpHeight = containerHeight * scaleFactor;
          const scaledUpWidth = containerWidth * scaleFactor;
          setContainerHeight(scaledUpHeight);
          setContainerWidth(scaledUpWidth);
        }}
        onMouseLeave={() => {
          const scaledDownHeight = containerHeight / scaleFactor;
          const scaledDownWidth = containerWidth / scaleFactor;
          setContainerHeight(scaledDownHeight);
          setContainerWidth(scaledDownWidth);
        }}
      >
        <div
          style={{
            width: '100%',
            height: `${containerWidth}px`,
            background: `url(${props.img})`,
            backgroundSize: 'cover'
          }}
        />
        <div className="bg-dark-mirage p-5" style={{ width: '100%', height: `${containerHeight - containerWidth}px` }}>
          <p className="text-xl">{props.name.toUpperCase()}</p>
          <p className="text-sm">{props.title}</p>
        </div>
      </div>
    </div>
  );
};

export default TeamVerticalPortrait;
