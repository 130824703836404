import { ScheduleProps } from '../../components/AscensionDay/Schedule';

export const dayOne: ScheduleProps = {
  leftColumnHeader: {
    title: 'Day 1',
    subtitle: 'July 14, 2021'
  },
  leftColumnEvents: [
    {
      title: 'Software Development and the DoD',
      subtitle: 'Keynote from Chief Software Officer Nicholas Chaillan'
    },
    {
      title: 'What is Agile',
      subtitle: 'Learn how to apply the 12 agile principles to drive innovation'
    },
    {
      title: 'Journey to the Operator',
      subtitle: 'Timeline of Battle Drill from discovery to continuous deployment'
    },
    {
      title: 'Agile in Action',
      subtitle: 'Get a close look at our products and how they are developed'
    },
    {
      title: 'Happy CAMPers',
      subtitle: 'Happy hour time to network with speakers and product teams. '
    }
  ],
  rightColumnHeader: {
    subtitle: 'July 15, 2021',
    title: 'Day 2'
  },
  rightColumnEvents: [
    {
      title: 'UX/UI in the DoD',
      subtitle: 'Keynote from Chief Experience Officer Colt Whittall'
    },
    {
      title: 'Leveraging the Tools of Platform One',
      subtitle: 'Platform One Leadership Panel'
    },
    {
      title: 'Space CAMP Strategic Plan',
      subtitle: 'How Space CAMP will partner with Deltas to develop apps'
    },
    {
      title: 'How We Tap Into Industry',
      subtitle: 'Discussion with SpaceWERX'
    },
    {
      title: 'Z Prefix & Supra Coders',
      subtitle: 'Vision for training the digital force'
    }
  ]
};
