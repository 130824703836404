import { ProductCardProps } from '../ImageDescriptionCards/ProductCard';

export const hasNext = (arr: ProductCardProps[], currIndex: number): boolean => {
  return currIndex < arr.length - 1;
};

export const getNext = (arr: ProductCardProps[], currIndex: number): string | undefined => {
  return arr[currIndex + 1].title;
};

export const getProjectCardBackground = (index: number): string => {
  return index % 2 !== 0 ? 'bg-dark-mirage' : '';
};
