import { useState, useEffect, useLayoutEffect } from 'react';
/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Takes an initial reading to see if the text content will overflow its container.
 * Retains its initial judgment even after the container expands.
 * @param ref useRef and attach it to the element you want to track
 * @returns Boolean: Whether the text content will overflow the container
 */
export const useWillOverflow = (ref: any) => {
  const [willOverflow, setWillOverflow] = useState<boolean>();
  const [scrollHeight, setScrollHeight] = useState<number>(0);
  const [clientHeight, setClientHeight] = useState<number>(0);

  useEffect(() => {
    if (ref.current) {
      setScrollHeight(ref.current.scrollHeight);
      setClientHeight(ref.current.clientHeight);
    }
  }, [ref]);

  useLayoutEffect(() => {
    setWillOverflow(scrollHeight > clientHeight);
  }, [scrollHeight, clientHeight]);

  return willOverflow;
};
