import React, { FunctionComponent, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Initiative } from '../../../common/Data/InitiativePageResources';
import { PRIMARY_ROUTES } from '../../../common/Routes/PrimaryRoutes';
import './InitiativeCarouselCard.css';

export const INITIATIVE_CAROUSEL_CARD_WIDTHS = 'xl:w-64 lg:w-56 md:w-56 sm:w-56 xs:w-48 ml-auto mr-auto';

const InitiativeCarouselCard: FunctionComponent<Initiative> = (props): ReactElement => {
  return (
    <div className={`text-white ${INITIATIVE_CAROUSEL_CARD_WIDTHS} ml-auto mr-auto flex flex-col items-center`}>
      <img src={props.image} className={`carousel-image border-2-white`} alt={`CarouselCard`} />
      <p className={`pb-6 mt-6 xl:text-xl lg:text-xl md:text-xl sm:text-xl xs:text-lg text-center w-full`}>
        {props.title}
      </p>
      <div className="carousel-to-link-spacing w-full bg-dark-dusty rounded-lg"></div>
      <Link
        to={PRIMARY_ROUTES.initiatives.concat(`/${props.name}`)}
        className={`initiativeCarouselCardButton hidden border-2 border-dark-mint py-2 px-6 xl:text-lg lg:text-lg md:text-base sm:text-base xs:text-xs text-center rounded-full mt-10 ml-auto mr-auto hover:bg-dark-mint hover:text-black capitalize`}
      >
        {`Explore ${props.name}`.toUpperCase()}
      </Link>
    </div>
  );
};

export default InitiativeCarouselCard;
