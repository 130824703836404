import AgileSummitImage from '../../assets/images/AgileSummitServices.png';
import BD_LOGO from '../../assets/images/ProductImages/BattleDrill.png';
import IRIS_LOGO from '../../assets/images/ProductImages/Iris.png';
import P9_LOGO from '../../assets/images/ProductImages/P9.png';
import { ProductCardProps } from '../../components/ImageDescriptionCards/ProductCard';

export const PlatformsAndServicesData: ProductCardProps[] = [
  {
    title: 'Gravity Platform',
    description: [
      'Sponsored and endorsed in partnership with the U.S. Space Force Chief Technology and Innovation Office (CTIO), Gravity is the premier Space Force accredited DevSecOps Platform-as-a-Service; built and managed by Space CAMP for Air Force and Space Force software product teams. ',
      'Hosted in partnership with Platform One on the AWS GovCloud, Gravity provides product teams with an accredited, non-vendor locked, Kubernetes based environment equipped with Development, Staging, and Production capabilities. Developers using the Graivty platform have access to an array of cost-optimizing tools, Continuous Integration/Continuious Delivery (CI/CD) pipelines, a hardened, container image repository, a Zero Trust Cloud Native Access Point enterprise architecture, and a dedicated customer support team to help them along the way.'
    ],
    image: 'https://www.youtube.com/embed/eSFSmMguur0',
    linkHeader: 'Gravity Portal',
    linkText: 'The Premier DevSecOps platform for the USSF (Login Required)',
    linkURL: 'https://gravity.spaceforce.mil/'
  },
  {
    title: 'Agile Summit Services',
    description: [
      'Sponsored and endorsed in partnership with the U.S. Space Force Chief Technology and Innovation Office (CTIO), Gravity is the premier Space Force accredited DevSecOps Platform-as-a-Service; built and managed by Space CAMP for Air Force and Space Force software product teams. ',
      'Hosted in partnership with Platform One on the AWS GovCloud, Gravity provides product teams with an accredited, non-vendor locked, Kubernetes based environment equipped with Development, Staging, and Production capabilities. Developers using the Graivty platform have access to an array of cost-optimizing tools, Continuous Integration/Continuious Delivery (CI/CD) pipelines, a hardened, container image repository, a Zero Trust Cloud Native Access Point enterprise architecture, and a dedicated customer support team to help them along the way.'
    ],
    image: AgileSummitImage
  }
];

export const SpaceDomainApplicationsData: ProductCardProps[] = [
  {
    title: 'Space Cockpit',
    description:
      'Space Cockpit is a space domain awareness application designed to provide the tactical space operator with a common operating picture of the space environment. This application fuses live DoD and commercial satellite data with 3-D gaming technology and machine learning to equip satellite operators with the tactical and visual information they need to manage space assets. Operators can visualize an asset’s location in orbit, simulate an asset’s future environment, and track an asset’s proximity to other space objects. This application is deployed on Platform One and available to the DoD.',
    image: 'https://www.youtube.com/embed/6VwLIP5Vxqk'
  },
  {
    title: 'IRIS',
    description:
      'The Integrated Range Information and Status (IRIS) is designed to increase situational awareness and support range operations by becoming a central authoritative source of data for the Western Range. IRIS is a web-based application that offers operators a more efficient process with visual overviews of live data needed for them to track, plan and execute their mission.',
    image: IRIS_LOGO
  },
  {
    title: 'Genesis',
    description:
      'Genesis is a web-based mission management system built for the 4th Space Operations Squadron (4 SOPS) and the Regional SATCOM Support Centers (RSSC). This product improves current scheduling capabilities and optimizes the utilization of MILSATCOM resources by replacing excel spreadsheets with a modern cloud-native application, accessible to users from multiple locations. The application is deployed on Platform One.',
    image: 'https://www.youtube.com/embed/dVpJbkT-1xk'
  },
  {
    title: 'Dragon Army Ops',
    description:
      "DRAGON Army's mission is to create a validated learning environment that integrates mission management, modeling and simulation, astro-analytics, and operational performance analytics capabilities to enhance the operations and training experience for our U.S. and Allied space operator teams. Our team  provide simple plug-and-play functionality for vendor partners to test and receive data-driven feedback on emerging ideas and technologies.  DRAGON Army conducts bi-weekly Ops Days to operationally test prototypes and existing tools for space traffic management and supplement DoD space control operations.",
    image: 'https://www.youtube.com/embed/zIg0rppLay4'
  }
];

export const MissionCriticalApplicationsData: ProductCardProps[] = [
  {
    title: 'SCINET',
    description:
      'The Sensitive Compartmented Information Nomination Evaluation Tool (SCINET)™ is a comprehensive, unclassified web application that enables the users to securely process SCI nominations in a manner that is compliant with the governing publications (e.g. DoDM 5105.21 series manuals, et al) and official records management mandates. It provides government security officials a framework for processing SCI nominations regarding an individual’s eligibility, need-to-know, formal access approval, and indoctrination action while providing transparency to senior leaders.',
    image: 'https://player.vimeo.com/video/464017699'
  },
  {
    title: 'Perimeter 9',
    description:
      'Perimeter 9 was selected by the Vice Chief of Staff of the US Air Force to be the pandemic management suite of the Air Force. It is a medical records tool that equips medical personnel with a real-time view of a patients’ health status. The tool facilitates contact tracing, testing updates, quarantine order coordination, and other tasks necessary to mitigating the impact of a health crisis. The original application was developed in 72 hours by the 30th Space Wing who then partnered with Space CAMP to get deployed onto Platform One and available to the entire DoD.',
    image: P9_LOGO
  },
  {
    title: 'Battle Drill',
    description:
      'Battle Drill is a cloud native web application that enables space operators with the ability to create, execute, and document operational scripts in near-real time. The application provides space operation crews with a common operating picture of event responses, with tools for tracking task lists, documenting updates, and managing complex workflows across teams. Battle Drill was built for the space enterprise in 3 months and was deployed to Platform One with a Certificate to Field (CtF) in 6 months.',
    image: BD_LOGO
  },
  {
    title: 'Decon+',
    description: [
      'Decon+ is a two phase project that supports mission critical operations by providing a secure and efficient normalization workflow management application for the laser community.',
      'Phase 1, beginning in July of 2020, was to teach DevSecOps in an immersive environment by developing a fully user-centered pathfinder application that provided orbital safety capabilities for laser-firing activities.',
      'Phase 2, which began September of 2021, Decon+ will operationally interface with the legacy system by automating the laser normalization process between Laser Owner Operators and Laser Clearinghouse.'
    ],
    image: 'https://www.youtube.com/embed/wFDTJWPf9MQ'
  }
];

export const OldApplicationsData: ProductCardProps[] = [
  {
    title: 'Decon+',
    description: [
      'Decon+ is a two phase project that supports mission critical operations by providing a secure and efficient normalization workflow management application for the laser community.',
      'Phase 1, beginning in July of 2020, was to teach DevSecOps in an immersive environment by developing a fully user-centered pathfinder application that provided orbital safety capabilities for laser-firing activities.',
      'Phase 2, which began September of 2021, Decon+ will operationally interface with the legacy system by automating the laser normalization process between Laser Owner Operators and Laser Clearinghouse.'
    ],
    image: 'https://www.youtube.com/embed/wFDTJWPf9MQ'
  },
  {
    title: 'SCINET',
    description:
      'The Sensitive Compartmented Information Nomination Evaluation Tool (SCINET)™ is a comprehensive, unclassified web application that enables the users to securely process SCI nominations in a manner that is compliant with the governing publications (e.g. DoDM 5105.21 series manuals, et al) and official records management mandates. It provides government security officials a framework for processing SCI nominations regarding an individual’s eligibility, need-to-know, formal access approval, and indoctrination action while providing transparency to senior leaders.',
    image: 'https://player.vimeo.com/video/464017699'
  },
  {
    title: 'Perimeter 9',
    description:
      'Perimeter 9 was selected by the Vice Chief of Staff of the US Air Force to be the pandemic management suite of the Air Force. It is a medical records tool that equips medical personnel with a real-time view of a patients’ health status. The tool facilitates contact tracing, testing updates, quarantine order coordination, and other tasks necessary to mitigating the impact of a health crisis. The original application was developed in 72 hours by the 30th Space Wing who then partnered with Space CAMP to get deployed onto Platform One and available to the entire DoD.',
    image: P9_LOGO
  },
  {
    title: 'Battle Drill',
    description:
      'Battle Drill is a cloud native web application that enables space operators with the ability to create, execute, and document operational scripts in near-real time. The application provides space operation crews with a common operating picture of event responses, with tools for tracking task lists, documenting updates, and managing complex workflows across teams. Battle Drill was built for the space enterprise in 3 months and was deployed to Platform One with a Certificate to Field (CtF) in 6 months.',
    image: BD_LOGO
  }
];
