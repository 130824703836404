import React, { FunctionComponent, ReactElement } from 'react';
import Hero from '../Hero/Hero';
import Claim from '../Claim/Claim';
import BannerImage from '../../assets/images/Career/CareersBanner.png';
import CareersResidency from '../../assets/images/Career/CareerResidency.png';
import { PADDING_Y } from '../AscensionDay/AscensionDay';
import Military from '../../assets/images/Career/Military.png';
import Government from '../../assets/images/Career/Government.png';
import Contractor from '../../assets/images/Career/Contractor.png';
import CareerOpportunityCard from './CareerOpportunityCard/CareerOpportunityCard';
import { PRIMARY_ROUTES } from '../../common/Routes/PrimaryRoutes';
import './CareersPage.css';

const MILITARY_PDF_LINK = 'https://drive.google.com/file/d/1syMQZnv8Ze9UdMgoc5XP0VWbSIVNkLzH/view?usp=share_link';
const GOVERNMENT_PDF_LINK = 'https://drive.google.com/file/d/12Eit49NRjag7U-DeMwehJWxGq-NAA10d/view?usp=share_link';
const FLEX_DIRECTION =
  'xl:flex-row lg:flex-col md:flex-col md:items-center sm:flex-col sm:items-center xs:flex-col xs:items-center';

const CareersPage: FunctionComponent = (): ReactElement => {
  return (
    <div>
      <Hero image={BannerImage} title="Careers" classString="bg-careers" />
      <Claim
        claim="Many Paths, One Mission"
        description="At Space CAMP, active duty military, government civilians, and commercial contractors work together in a badgeless environment to support the mission of our space operators."
      />
      <div className={`text-white items-center flex flex-col w-full ${PADDING_Y}`}>
        <p className={`xl:text-5xl lg:text-5xl md:text-4xl sm:text-3xl xs:text-3xl font-oswald px-4`}>Join Our Team</p>
        <div className={`flex xl:justify-between w-2/3 ${FLEX_DIRECTION}`}>
          <CareerOpportunityCard
            img={Military}
            title="Military"
            description="We are hiring experts in Agile software development, space operations, machine learning and DevSecOps. Remote opportunities available."
            buttonText="VIEW OPENINGS"
            url={MILITARY_PDF_LINK}
          />
          <CareerOpportunityCard
            img={Government}
            title="Government"
            description="We are hiring experts in Agile software development, space operations, machine learning and DevSecOps. Remote opportunities available."
            buttonText="VIEW OPENINGS"
            url={GOVERNMENT_PDF_LINK}
          />
          <CareerOpportunityCard
            img={Contractor}
            title={`Contractors & SBIRS`}
            description="We work with contractors and SBIRS who bring expertise in sofware development, devsecops, and the space enterprise. Contact us for more information."
            buttonText="CONTACT US"
            url={PRIMARY_ROUTES.contact}
          />
        </div>
      </div>
      <div className={`flex xl:flex-row lg:flex-col md:flex-col sm:flex-col xs:flex-col bg-dark-mirage`}>
        <div className="text-white w-full xl:w-1/3 xl:ml-48 xl:mr-24 xl:my-auto p-8">
          <p className="xl:text-5xl lg:text-3xl md:text-3xl sm:text-xl xs:text-xl font-oswald text-white text-opacity-87">
            Software Development Residency Program
          </p>
          <p className="xl:text-2xl lg:text-lg md:text-base sm:text-base xs:text-base text-left mt-8 text-white text-opacity-74">
            We are looking for innovative military (CONUS - Active Duty) and government civilians to join our mission to
            transform the digital force through rapid software development. Based on your skills, we will match you with
            one of our product teams, where you will gain experience in agile software development.
          </p>
          <p className="xl:text-2xl lg:text-lg md:text-base sm:text-base xs:text-base text-left mt-8 text-white text-opacity-74">
            This is a 6-month program, with options to complete remotely or apply for TDY. To apply, email your resume
            to{' '}
            <a href="mailto:spacecamp@afrl.af.mil" className="text-dark-mint underline">
              spacecamp@afrl.af.mil
            </a>
            .
          </p>
        </div>
        <div>
          <img src={CareersResidency} alt="Residency Program" className="residency-program" />
        </div>
      </div>
    </div>
  );
};

export default CareersPage;
