import React, { FunctionComponent, ReactElement } from 'react';
import { PortraitProps } from './Portrait.props';

const VerticalPortrait: FunctionComponent<PortraitProps> = (props): ReactElement => {
  return (
    <div className={`${props.containerClass}`}>
      <div style={{ width: '360px', height: '360px', background: `url(${props.img})`, backgroundSize: 'cover' }} />
      <div
        className="bg-dark-mirage p-8"
        style={{
          height: '180px',
          width: '360px'
        }}
      >
        <p className="text-xl mb-2">{props.name}</p>
        <p className="text-sm">{props.title},</p>
        <p className="text-sm">{props.org}</p>
      </div>
    </div>
  );
};

export default VerticalPortrait;
