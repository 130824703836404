import React, { FunctionComponent } from 'react';
import Hero from '../Hero/Hero';
import SpaceCampTeam from '../../assets/images/SC-Govt-Team-Jul2022.png';
import Claim from '../Claim/Claim';
import TeamRoster from './Roster/TeamRoster';
import SpaceCampHallmarks from './Hallmark/SpaceCampHallmarks';
import CallToAction from './CallToAction/CallToAction';

export const TEAM_TITLE = 'Our Team';
export const CLAIM_DESCRIPTION =
  'An official branch of the Air Force Research Laboratory Space Vehicles Directorate (AFRL/RV), Space CAMP is a DoD Software Factory that fosters a culture of egoless collaboration and innovation. By structuring our software factory as a "flat organization" with our focus on the USSF vision to achieve global digital dominance and not a top down traditional hierarchy, we empower our product teams with autonomy, self-determination in mastery, and a shared sense of purpose.';

export const TeamPage: FunctionComponent = () => {
  return (
    <div>
      <Hero image={SpaceCampTeam} title={TEAM_TITLE} />
      <Claim claim="We Are Space CAMP" description={CLAIM_DESCRIPTION} />
      <TeamRoster />
      <SpaceCampHallmarks />
      <CallToAction />
    </div>
  );
};

export default TeamPage;
