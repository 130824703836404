import moment from 'moment';
import { TagEnum } from '../enums/Tag.enum';
import { ValidEvent } from '../Interfaces/EventInfo';
import { PRIMARY_ROUTES } from '../Routes/PrimaryRoutes';

export const DefaultEvents: ValidEvent[] = [
  {
    eventName: 'space camp social',
    start: moment('2021-04-07T14:00:00'),
    end: moment('2021-04-07T15:30:00'),
    tags: [TagEnum.IN_PERSON],
    description:
      "Space CAMP's hosting a social event during Space Symposium. Join us to network and for new collaboration opportunities.",
    link: PRIMARY_ROUTES.spaceCampSocial
  },
  {
    eventName: 'space camp open house',
    start: moment('2021-08-25T13:30:00'),
    end: moment('2021-08-25T15:30:00'),
    tags: [TagEnum.IN_PERSON, TagEnum.ONLINE],
    description:
      'Learn about our mission, how to work with us, and get an inside look at the applications we are developing for the Space Force.'
  },
  {
    eventName: 'ascension days 2.0',
    start: moment('2021-07-14T00:00:00'),
    end: moment('2021-07-15T00:00:00'),
    tags: [TagEnum.IN_PERSON, TagEnum.ONLINE],
    description:
      'A two day event that will equip USSF Deltas with the information, tools, and language needed to successfully lead the digital transformation.',
    link: PRIMARY_ROUTES.ascensionDay
  },
  {
    eventName: 'battle drill demo day',
    start: moment('2021-07-13T09:00:00'),
    end: moment('2021-07-13T13:00:00'),
    tags: [TagEnum.IN_PERSON, TagEnum.ONLINE],
    description:
      'Get an in depth look at Battle Drill, an Event Management Application built to enable collaboration across space operations.'
  },
  {
    eventName: 'ascension days 1.0',
    start: moment('2021-04-15T00:00:00'),
    end: moment('2021-04-16T00:00:00'),
    tags: [TagEnum.IN_PERSON, TagEnum.ONLINE],
    description:
      'Learn about how Space CAMP is partnering with USSF Deltas and Combat Development Teams to develop agile technology solutions.'
  },
  {
    eventName: 'delta innovation summit',
    start: moment('2021-03-23T09:00:00'),
    end: moment('2021-03-23T17:00:00'),
    tags: [TagEnum.IN_PERSON, TagEnum.ONLINE],
    description:
      'The event will focus on defining a CONOP for Delta-level innovation discussing core principles, processes, and critical enablers.'
  }
];
