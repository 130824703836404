import React, { FunctionComponent, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import LeftArrowIcon from '../../assets/icons/LeftArrow.svg';
import RightArrowIcon from '../../assets/icons/RightArrow.svg';
import { Partner, Partners } from '../../common/Data/Partners';
import { generateScrollToContactDispatcher } from '../../common/Helpers/ScrollHelpers';
import { PRIMARY_ROUTES } from '../../common/Routes/PrimaryRoutes';
import LeftArrow from '../CarouselArrows/LeftArrow';
import RightArrow from '../CarouselArrows/RightArrow';
import './PartnerBanner.css';

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  focusOnSelect: true,
  centerMode: true,
  arrows: true,
  nextArrow: (
    <RightArrow>
      <img src={RightArrowIcon} alt="RightArrowIcon" />
    </RightArrow>
  ),
  prevArrow: (
    <LeftArrow>
      <img src={LeftArrowIcon} alt="LeftArrowIcon" />
    </LeftArrow>
  ),
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: true,
        centerMode: true
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        centerMode: false
      }
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        centerMode: false
      }
    }
  ]
};

const PartnerCard: FunctionComponent<Partner> = (props): ReactElement => {
  return (
    <div className={`flex flex-col items-center justify-center text-white partnerCard`}>
      <a target="__blank" href={props.url}>
        <img src={props.img} alt={props.name} className="partner-card-image"></img>
        <p className="pt-4 ml-auto mr-auto w-full text-center text-xl font-bold partnerName">{props.name}</p>
      </a>
    </div>
  );
};

const PartnerBanner: FunctionComponent = (): ReactElement => {
  return (
    <div className="partner-banner-main">
      <div className="partnerCarousel xl:px-20 px-10 py-10 ml-auto mr-auto">
        <p className="text-center xl:text-5xl lg:text-5xl md:text-4xl sm:text-3xl xs:text-3xl mb-4 font-oswald text-white px-4 w-full">
          Our Partners
        </p>
        <Slider {...settings}>
          {Partners.map(p => (
            <PartnerCard key={`${p.name}:partnerCard`} {...p} />
          ))}
        </Slider>
      </div>
      <div className="w-full pb-16 flex justify-center">
        <Link
          data-testid="partner-with-us-link"
          to={PRIMARY_ROUTES.contact}
          onClick={generateScrollToContactDispatcher()}
          className="partner-with-us-link border-2 border-dark-mint p-2 w-32 text-lg text-white text-center rounded-full bg-dark-mirage hover:bg-dark-mint hover:text-black mr-auto ml-auto mt-4"
        >
          PARTNER WITH US
        </Link>
      </div>
    </div>
  );
};

export default PartnerBanner;
