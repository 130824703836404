import React, { useEffect, useRef, useState } from 'react';
import { FunctionComponent } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './NavTab.css';

/* eslint-disable @typescript-eslint/no-explicit-any */

export interface NavTabProps {
  tabLink: string;
  tabName: string;
  className?: string;
}

const NavTab: FunctionComponent<NavTabProps> = props => {
  const wrapperClass = props.className ? props.className : 'flex flex-col items-center';
  const ref = useRef<any>();
  const [barWidth, setBarWidth] = useState<number>();
  const { pathname } = useLocation();

  useEffect(() => {
    setBarWidth(ref.current?.clientWidth);
  }, [ref]);

  return (
    <div className={wrapperClass} title={`To ${props.tabName.toLowerCase()} page.`}>
      <Link
        ref={ref}
        className={`mx-2 px-2 xl:text-xl lg:text-xl md:text-xl sm:text-md xs:text-md font-light hover:text-dark-mint ${
          pathname.includes(props.tabLink) && 'text-dark-mint'
        }`}
        to={`${props.tabLink}`}
      >
        {props.tabName}
      </Link>
      <div
        className={`nav-tab-highlight rounded-lg bg-transparent md:mx-auto sm:mx-auto xs:mx-auto ${
          pathname.includes(props.tabLink) && 'bg-dark-mint'
        }`}
        style={{ width: `${barWidth}px` }}
      ></div>
    </div>
  );
};

export default NavTab;
