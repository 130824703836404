import React, { FunctionComponent, ReactElement, RefObject, createRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BannerImage from '../../assets/images/WorkBanner.png';
import {
  MissionCriticalApplicationsData,
  PlatformsAndServicesData,
  SpaceDomainApplicationsData
} from '../../common/Data/WorkPageResources';
import { sleepThenScrollToRef } from '../../common/Helpers/ScrollHelpers';
import Claim from '../Claim/Claim';
import Hero from '../Hero/Hero';
import ProductCard, { ProductCardProps } from '../ImageDescriptionCards/ProductCard';
import ProductCardAlt from '../ImageDescriptionCards/ProductCardAlt';
import SpaceDomainApplications from '../SpaceDomainApplications/SpaceDomainApplications';
import { getNext, getProjectCardBackground, hasNext } from './WorkPageHelpers';

export type ProjectRef = RefObject<HTMLDivElement> | null;
export interface WorkPageParams {
  name: string;
}

const WorkPage: FunctionComponent = (): ReactElement => {
  const { name } = useParams() as WorkPageParams;
  const [currentProject, setCurrentProject] = useState<string>();
  const [projectRefs] = useState<Map<string, ProjectRef>>(
    new Map([...PlatformsAndServicesData, ...MissionCriticalApplicationsData].map(p => [p.title, createRef()]))
  );

  useEffect(() => {
    sleepThenScrollToRef(name, projectRefs, setCurrentProject);
  }, [name, projectRefs]);

  useEffect(() => {
    sleepThenScrollToRef(currentProject, projectRefs, setCurrentProject, 0);
  }, [currentProject, projectRefs]);

  const productCardRenderHelper = (proj: ProductCardProps, projInd: number) => {
    if (projInd % 2 === 1) {
      return (
        <ProductCardAlt
          showArrow={hasNext(MissionCriticalApplicationsData, projInd) || MissionCriticalApplicationsData.length > 0}
          arrowClick={() => {
            if (hasNext(PlatformsAndServicesData, projInd))
              setCurrentProject(getNext(PlatformsAndServicesData, projInd));
            else setCurrentProject(MissionCriticalApplicationsData[0].title);
          }}
          cardRef={projectRefs.get(proj.title)}
          key={`${proj.title}:${projInd}`}
          {...proj}
          wrapperClass={getProjectCardBackground(projInd)}
        />
      );
    } else {
      return (
        <ProductCard
          showArrow={hasNext(MissionCriticalApplicationsData, projInd) || MissionCriticalApplicationsData.length > 0}
          arrowClick={() => {
            if (hasNext(PlatformsAndServicesData, projInd))
              setCurrentProject(getNext(PlatformsAndServicesData, projInd));
            else setCurrentProject(MissionCriticalApplicationsData[0].title);
          }}
          cardRef={projectRefs.get(proj.title)}
          key={`${proj.title}:${projInd}`}
          {...proj}
          wrapperClass={getProjectCardBackground(projInd)}
        />
      );
    }
  };

  return (
    <div className={`workPageWrapper`}>
      <Hero image={BannerImage} title="Our Work" classString="bg-work" />
      <SpaceDomainApplications
        title="Platform & Services"
        description="As part of our new Base Camp Initiative, we provide our product teams and Supra Coders with a robust DevSecOps platform to test and field new innovations. To help bridge skill gaps and reinforce effective designs and deployments, we provide product teams with Agile development, project management, and User Centered Design support services."
      />
      <div className={`flex flex-row justify-evenly flex-wrap`}>
        {PlatformsAndServicesData.map((project, projectIndex) => productCardRenderHelper(project, projectIndex))}
      </div>
      <Claim
        claim="Space Domain Applications"
        description="Our teams specialize in the development of space domain applications that empower space operators with the tools and information they need to execute their mission."
      />
      <div className={`flex flex-row justify-evenly flex-wrap`}>
        {SpaceDomainApplicationsData.map((project, projectIndex) => productCardRenderHelper(project, projectIndex))}
      </div>
      <Claim
        claim="Mission Critical Applications"
        description="We develop mobile and web applications for the Air Force and DoD in mission critical areas, including medical records, pandemic response, and procedure management."
      />
      <div className={`flex flex-row justify-evenly flex-wrap`}>
        {MissionCriticalApplicationsData.map((project, projectIndex) => productCardRenderHelper(project, projectIndex))}
      </div>
    </div>
  );
};

export default WorkPage;
