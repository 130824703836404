import React, { FunctionComponent, ReactElement } from 'react';
import './LocateUs.css';

export interface LocateUsProps {
  scrollRef?: React.LegacyRef<HTMLDivElement>;
}

const LocateUs: FunctionComponent<LocateUsProps> = (props): ReactElement => {
  return (
    <div
      ref={props.scrollRef}
      className={`bg-transparent flex xl:flex-row lg:flex-col md:flex-col sm:flex-col xs:flex-col w-full justify-around items-center text-white mx-10 my-16`}
    >
      <div
        className={
          'flex flex-col font-bold justify-around text-left xl:mt-0 xs:mt-10 xl:w-2/5 lg:w-2/5 md:w-2/5 sm:w-4/5 xs:w-4/5'
        }
      >
        <div className={`flex flex-col ml-auto mr-auto`}>
          <p className={'text-4xl mb-10'}>Our Headquarters</p>
          <p className={'text-2xl '}>Catalyst Campus for</p>
          <p className={'text-2xl '}>Technology & Innovation</p>
          <p className={'text-xl font-light'}>545 Pikes Peak Avenue, Suite 300</p>
          <p className={'text-xl font-light mb-10'}>Colorado Springs, CO 80903</p>
          <a
            href="https://goo.gl/maps/MCw2DhTV3ThVHGKw5"
            target="__blank__"
            className="google-maps-link border-2 border-dark-mint p-2 w-32 text-lg text-center rounded-full hover:bg-dark-mint hover:text-black"
          >
            Get Directions
          </a>
        </div>
      </div>
      <div
        className={
          'pt-10 xl:block lg:block md:hidden sm:hidden xs:hidden self-center xl:w-2/5 lg:w-3/5 border-dark-dusty rounded-lg'
        }
      >
        <iframe
          data-testid="space-camp-location-map"
          title="Space Camp Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3107.993632786884!2d-104.81776838389051!3d38.832606658364234!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8713451bc2948fed%3A0x643c7897e6a5c824!2s545%20E%20Pikes%20Peak%20Ave%2C%20Colorado%20Springs%2C%20CO%2080903!5e0!3m2!1sen!2sus!4v1652200966045!5m2!1sen!2sus"
          height="450"
          style={{ filter: 'invert(95%)' }}
          className="google-maps-iframe xl:w-4/5 lg:w-full"
          aria-hidden="false"
          tabIndex={0}
        ></iframe>
      </div>
    </div>
  );
};

export default LocateUs;
