import React, { FunctionComponent, ReactElement } from 'react';
import InnovationCard from './InnovationCard/InnovationCard';
import SecurityIcon from '../../../assets/icons/SecurityIcon.svg';
import CloudStorageIcon from '../../../assets/icons/CloudStorageIcon.svg';
import ContinuousDeploymentIcon from '../../../assets/icons/ContinuousDeploymentIcon.svg';
import { PRIMARY_ROUTES } from '../../../common/Routes/PrimaryRoutes';
import { PADDING_Y } from '../../AscensionDay/AscensionDay';

const HEADER_SIZE = 'xl:text-5xl lg:text-5xl md:text-4xl sm:text-3xl xs:text-3xl';
const FLEX_DIRECTION =
  'xl:flex-row lg:flex-col md:flex-col md:items-center sm:flex-col sm:items-center xs:flex-col xs:items-center';
const INNOVATION_LINK_STYLE =
  'border-2 border-dark-mint py-2 px-6 text-lg text-center rounded-full ml-auto mr-auto hover:bg-dark-mint hover:text-black font-light';

const Innovation: FunctionComponent = (): ReactElement => {
  return (
    <div className={`text-white justify-center items-center flex flex-col w-full ${PADDING_Y}`}>
      <p className={`${HEADER_SIZE} font-oswald text-dark-mint px-4`}>Coding at the Speed of Need</p>
      <div className={`flex mt-16 justify-between w-2/3 ${FLEX_DIRECTION}`}>
        <InnovationCard
          icon={SecurityIcon}
          title="Continuous Authority to Operate (cATO)"
          textContent="Our solutions meet DoD security and DevSecOps standards. Each product we develop is deployed with a Continuous Authority to Operate (cATO), through Platform One. This allows our teams to continue iterating on the application in production."
          url="https://p1.dso.mil/#/"
          buttonText="Visit Platform 1"
          classString={`${INNOVATION_LINK_STYLE}`}
        />
        <InnovationCard
          icon={ContinuousDeploymentIcon}
          title="Continuous Delivery In Production"
          textContent="Our teams are able to deploy system patches at the speed of need, thanks to our CI/CD pipelines that can automatically test and deploy updates to the production environment in as little as an hour. This agile process ensures that your team has the best tool for the fight."
          url={PRIMARY_ROUTES.process}
          buttonText="View Our Process"
          classString={`${INNOVATION_LINK_STYLE}`}
        />
        <InnovationCard
          icon={CloudStorageIcon}
          title="Developed Low Side, Deployed High Side"
          textContent="Our products can be designed and programmed in an unclassified environment and then containerized and deployed securely to all applicable DoD networks. This process encourages collaboration and ensures the best solutions reach the operator."
          url={PRIMARY_ROUTES.work}
          buttonText="View Our Products"
          classString={`${INNOVATION_LINK_STYLE}`}
        />
      </div>
    </div>
  );
};

export default Innovation;
