import React, { createRef, FunctionComponent, ReactElement, RefObject, useEffect, useState } from 'react';
import Hero from '../Hero/Hero';
import InitiativeImage from '../../assets/images/Initiatives.png';
import Claim from '../Claim/Claim';
import { Initiatives } from '../../common/Data/InitiativePageResources';
import InitiativeCard from '../ImageDescriptionCards/InitiativeCard';
import { useParams } from 'react-router-dom';
import { sleepThenScrollToRef } from '../../common/Helpers/ScrollHelpers';
import { getProjectCardBackground } from '../WorkPage/WorkPageHelpers';

/* eslint-disable @typescript-eslint/no-explicit-any */

export type InitiativeRef = RefObject<HTMLDivElement> | null;

const InitiativesPage: FunctionComponent = (): ReactElement => {
  const { name } = useParams() as any;
  const [currentName, setCurrentName] = useState<string>();
  const [initiativeRefs] = useState<Map<string, InitiativeRef>>(new Map(Initiatives.map(i => [i.name, createRef()])));

  useEffect(() => {
    sleepThenScrollToRef(name, initiativeRefs, setCurrentName);
  }, [name, initiativeRefs]);

  useEffect(() => {
    sleepThenScrollToRef(currentName, initiativeRefs, setCurrentName, 0);
  }, [currentName, initiativeRefs]);

  return (
    <div>
      <Hero image={InitiativeImage} title="Core Initiatives" classString="xl:bg-left-top bg-initiatives" />
      <Claim
        claim="Agile Technology Solutions"
        description="Our vision is to transform the digital force through rapid software development, collaborative innovation, and servant leadership. We are achieving this through our seven mission initiatives."
      />
      <div className={`flex flex-row justify-evenly flex-wrap`}>
        {Initiatives.map((initiative, initiativeIndex) => (
          <InitiativeCard
            showArrow={initiativeIndex < Initiatives.length - 1}
            arrowClick={() => Initiatives[initiativeIndex + 1] && setCurrentName(Initiatives[initiativeIndex + 1].name)}
            key={`${initiative.title}:${initiativeIndex}`}
            cardRef={initiativeRefs.get(initiative.name)}
            {...initiative}
            wrapperClass={getProjectCardBackground(initiativeIndex)}
          />
        ))}
      </div>
    </div>
  );
};

export default InitiativesPage;
