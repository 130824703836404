import React, { FunctionComponent } from 'react';

const ConnectWithUs: FunctionComponent = () => (
  <div
    className="text-white
        font-thin
        mb-4
        xl:text-base 
        lg:text-base 
        md:text-base 
        sm:text-base 
        xs:text-sm"
  >
    <p
      className="text-dark-mint
        mb-2
        font-normal
        xl:text-2xl
        lg:text-xl
        md:text-xl
        sm:text-lg
        xs:text-2xl"
      aria-label="Connect With Us Section for Footer"
    >
      Connect With Us
    </p>

    <div className="mt-3" id="footerAddressSection" aria-label="Address Section">
      <p className="text-dark-mint">Address</p>
      <p>545 Pikes Peak Ave</p>
      <p>Suite 300 </p>
      <p>Colorado Springs, CO 80903</p>
    </div>

    <div className="mt-3" id="footerEmailSection" aria-label="Email Section">
      <p className="text-dark-mint">Email</p>
      <p>
        <a href="mailto: spacecamp@afrl.af.mil">spacecamp@afrl.af.mil</a>
      </p>
    </div>
  </div>
);

export default ConnectWithUs;
