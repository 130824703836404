import React, { FunctionComponent, ReactElement } from 'react';
import WhatMakesSCAwesomeVenn from '../../../assets/images/WhatMakesSCAwesomeVenn.png';

const REASONS: { header: string; description: string }[] = [
  {
    header: 'Mastery',
    description: `We believe that innovation is best realized when our team members are empowered with the opportunity to be their best selves. We are committed to fostering an environment where every person’s voice is heard and each member has the resources and support they need to continuously hone and master their craft.`
  },
  {
    header: 'Purpose',
    description: `We are committed to servant leadership, the empowerment of others, to perceive more than to be perceived, and having the courage to take calculated risks. Unified in the advancement of a digitally dominant Space Force, together we embrace new approaches to solving problems and deliver operator-centered, future-focused solutions to Guardians.`
  },
  {
    header: 'Autonomy',
    description: `We challenge the status quo. Every member of our team is encouraged to share ideas regarding our processes, technology, products, and methods to ensure we create an interconnected software factory uniquely equipped for adaptation and innovation. Unbound by the rigid hierarchy of traditional governmental institutions, we are able to pivot organically to support rapidly changing mission dynamics.`
  },
  {
    header: 'Together We Are Awesome',
    description: `We are rebels with a cause. By breaking silos across disciplines we eliminate the “stay-in-your-lane” mentality and ensure a more robust, balanced collaboration within product teams. We maintain a “flat”, egoless culture composed of Space “CAMPers” empowered to deliver impactful mission-enhancing capabilities to U.S. Space Force Guardians.`
  }
];

const HEADER = 'What Makes Space CAMP Awesome?';
const PADDING_Y = 'xl:py-20 lg:py-20 md:py-20 sm:py-8 xs:py-8';

export const SpaceCampHallmarks: FunctionComponent = (): ReactElement => {
  return (
    <div className={`flex xl:flex-row xs:flex-col xs:px-8 justify-center text-white bg-dark-mirage ${PADDING_Y}`}>
      <div className="flex xl:justify-end xs:justify-center justify-right xl:w-1/2 xs:w-full xl:pl-8">
        <div className="flex flex-col xl:justify-start xs:justify-center xl:pr-8" style={{ width: '49.625rem' }}>
          <div className="xl:text-4.75xl lg:text-3xl md:text-3xl sm:text-xl xs:text-xl xl:text-left xs:text-center mb-10 font-oswald text-opacity-87">
            {HEADER}
          </div>
          <div className="xl:hidden">
            <img src={WhatMakesSCAwesomeVenn} alt={'Mastery Autonomy Purpose Venn Diagram'} />
          </div>
          <div className="xl:block xs:hidden" style={{ maxWidth: '43.375rem' }}>
            <img src={WhatMakesSCAwesomeVenn} alt={'Mastery Autonomy Purpose'} />
          </div>
        </div>
      </div>
      <div className="xl:block xs:flex xs:flex-row xs:justify-center xl:w-1/2 xs:w-full text-opacity-74">
        <div className="flex flex-row flex-wrap sm:justify-between xs:justify-center max-w-screen-md xl:pr-8 xl:mt-14 sm:mt-20 xs:pt-12">
          {REASONS.map(reason => (
            <div key={`reason-${reason.header}`} className="mb-4 " style={{ width: '18.25rem' }}>
              <div className="text-2xl mb-4 font-oswald">{reason.header}</div>
              <div>{reason.description}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SpaceCampHallmarks;
