import React, { FunctionComponent } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { PRIMARY_ROUTES } from '../../common/Routes/PrimaryRoutes';
import NavTab from './NavTab/NavTab';

import SpaceCampLogoAndTitle from './../../assets/images/Logos/SpaceCampLogoWithTitle-wide.svg';

const NavBar: FunctionComponent = () => {
  const activeTab = useLocation().pathname;
  return (
    <div className="sticky top-0 z-10 flex flex-row justify-between items-center bg-dark-mirage text-white px-6 py-2 madShadow h-20">
      <div title="To home page.">
        <Link to={PRIMARY_ROUTES.home} className="flex flex-row justify-between">
          <img src={SpaceCampLogoAndTitle} alt="Space Camp Logo and Title" style={{ width: '480px', height: '60px' }} />
        </Link>
      </div>
      <div data-testid="nav-link-container" className="flex flex-row items-center">
        <NavTab tabName={'HOME'} tabLink={PRIMARY_ROUTES.home} />
        <NavTab tabName={'INITIATIVES'} tabLink={PRIMARY_ROUTES.initiatives} />
        <NavTab tabName={'PROCESS'} tabLink={PRIMARY_ROUTES.process} />
        <NavTab tabName={'WORK'} tabLink={PRIMARY_ROUTES.work} />
        <NavTab tabName={'EVENTS'} tabLink={PRIMARY_ROUTES.events} />
        <NavTab tabName={'CAREERS'} tabLink={PRIMARY_ROUTES.careers} />
        <NavTab tabName={'TEAM'} tabLink={PRIMARY_ROUTES.team} />
        <Link
          className={`shadow-md border-2 border-dark-mint text-center rounded-full px-6 py-2 xl:ml-6 lg:ml-6 xl:mt-0 lg:mt-0 md:mt-2 sm:mt-2 xs:mt-2 font-light ${
            activeTab === PRIMARY_ROUTES.contact ? 'bg-dark-mint' : 'hover:bg-dark-mint hover:text-dark-mirage'
          }`}
          title="To contact us page."
          to={PRIMARY_ROUTES.contact}
        >
          CONTACT US
        </Link>
      </div>
    </div>
  );
};

export default NavBar;
