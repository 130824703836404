import React, { FunctionComponent, ReactElement } from 'react';
import InternalOrExternalLink from '../../InternalOrExternalLink/InternalOrExternalLink';
import './CareerOpportunityCard.css';

export interface CareerOpportunitiesCardProps {
  img: string;
  title: string;
  description: string;
  buttonText: string;
  url: string;
}

const CareerOpportunityCard: FunctionComponent<CareerOpportunitiesCardProps> = (props): ReactElement => {
  return (
    <div className="xl:block lg:block md:block sm:block xs:block mt-12 bg-dark-deepSea">
      <img src={props.img} alt="" />
      <div className="career-opportunity-card-content p-6">
        <p className="font-oswald text-2xl text-white text-opacity-87 mb-4">{props.title}</p>
        <p className="text-base text-white text-opacity-74 mb-8">{props.description}</p>
        <InternalOrExternalLink
          url={props.url}
          text={props.buttonText}
          classString="flex justify-center mx-auto border-2 border-dark-mint p-2 w-64 text-lg text-center bg-transparent rounded-full hover:bg-dark-mint hover:text-black"
        />
      </div>
    </div>
  );
};

export default CareerOpportunityCard;
